<template>
    <div class="container">
        <div class="block block-left">
            <Logo>marthijnbeilschmidt.nl</Logo>
        </div>
        <div class="block block-right">
            <div class="contact">
                <Button href="#contact" secondary>Neem contact op</Button>
            </div>
        </div>
        <Hr/>
    </div>
</template>

<style lang="scss" scoped>
.container {
    position: relative;
    z-index: 5;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: $gutter $gutter 0;
    margin-bottom: $gutter;
    background-color: $bg-1;

    @media screen and (max-width: $xs-width) {
        padding: calc($gutter / 2) calc($gutter / 2) 0;
        margin-bottom: calc($gutter / 2);
    }

    :deep(hr) {
        margin-top: $gutter;
        margin-bottom: 0;

        @media screen and (max-width: $xs-width) {
            margin-top: calc($gutter / 2);
        }
    }

    .block {
        flex: 1 auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: calc($gutter / 2);

        .contact {
            @media screen and (max-width: $xs-width) {
                display: none;
            }
        }
    }

    .block-right {
        flex: 1 auto;
        justify-content: flex-end;
    }
}
</style>
