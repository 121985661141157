<template>
    <div class="footer-wrapper">
        <Lines/>
        <div class="footer-container">
            <Hr/>
            <div class="container">
                <div class="column">
                    <Logo>marthijnbeilschmidt.nl</Logo>
                    <div class="column-data">
                        <div class="social">
                            <svgo-email/>
                            <a :href="`mailto:${email}`" v-text="email"/>
                        </div>
                        <div class="social">
                            <svgo-linkedin/>
                            <a :href="linkedin" target="_blank" v-text="'LinkedIn'"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const email = "info@absolit.nl";
const linkedin = "https://www.linkedin.com/in/pondake/";
</script>

<style lang="scss" scoped>
.footer-wrapper {
    position: relative;
    z-index: 15;
    width: 100%;
    //margin-top: calc($gutter * 2);

    .footer-container {
        position: relative;
        z-index: 2;
        background-color: $bg-1;
    }

    :deep(.lines) {
        top: 30px;
        //transform: translate(-50%, 0%);
        z-index: -1;
        pointer-events: none;
    }

    :deep(hr) {
        margin-top: 0;
    }

    .container {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding: $gutter;

        @media screen and (max-width: $xs-width) {
            padding: calc($gutter / 2);
        }

        .column {
            flex: 1 auto;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            gap: calc($gutter / 2);

            &-data {
                display: flex;
                width: 100%;
                flex-flow: row wrap;
                gap: calc($gutter / 6);
                align-items: center;
                font-size: em(14px);

                .social {
                    display: flex;
                    width: 100%;
                    gap: calc($gutter / 4);
                    align-items: center;

                    .nuxt-icon {
                        color: $txt-white-darker;
                        @include fixed-ar(20px)
                    }
                }

                a {
                    color: $txt-white-darker;
                    text-decoration: none;
                }
            }

            &-right {
                flex: 1 auto;
                justify-content: flex-end;
            }
        }
    }
}
</style>
