<template>
    <div class="logo">
        <svgo-logo-w :height="30" :width="30"/>
        <div class="initials">
            <slot/>
        </div>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.logo {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: calc($gutter / 2);
    font-size: em(14px);
    font-weight: 500;
    letter-spacing: 0.5px;

    svg {
        display: inline-block;
        min-width: 30px;
        min-height: 30px;
        transition: all .2s ease-in-out;
    }
}

.logo:hover svg, .logo svg:hover {
    transform: rotate(90deg);
}

.logo:hover {
    cursor: none;
}
</style>
